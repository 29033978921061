<div class="row justify-content-end">
    <div class="col-12 col-md-auto" id="releaseDropdown">
        <div ngbDropdown class="d-inline-block">
            <button class="btn btn-light btn-outline-dark " ngbDropdownToggle>{{releaseName ? releaseName : 'releases' | translate}}</button>
            <div ngbDropdownMenu aria-labelledby="releaseDropdown">
              <button class="dropdown-item" *ngFor="let release of releases" [ngClass]="release.name ===  releaseName ? 'active' : ''" (click)="filterByReleaseName(release.name)">{{release.name}}</button>
            </div>
          </div>
    </div>
    <div class="col-12 col-md-auto" id="artistDropdown">
        <div ngbDropdown class="d-inline-block">
            <button class="btn btn-light btn-outline-dark " ngbDropdownToggle>{{artistName ? artistName : 'artists' | translate}}</button>
            <div ngbDropdownMenu aria-labelledby="artistDropdown">
              <button class="dropdown-item" *ngFor="let artist of artists" [ngClass]="artist.fullName ===  artistName ? 'active' : ''" (click)="filterByArtistName(artist.fullName)">{{artist.fullName}}</button>
            </div>
          </div>
    </div>
    <div class="col-12 col-md-4">
        <form [formGroup]="nameForm" class="form-inline">
            <div class="input-group">
                <div class="input-group-addon">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" class="bi bi-search" viewBox="0 0 19 19">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                    </span>
                </div>
                <input formControlName="name" class="form-control">
            </div>  
        </form>
    </div> 
</div>

<div class="row justify-content-center">
    <div *ngIf="loaded == false" class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="apiNotResponding" class="m-5">
        <span>{{'apiNotResponding' | translate }}</span>
    </div>
    <div class="col-6 col-md-4 video" *ngFor="let video of videos">
        <div class="embed-responsive embed-responsive-16by9" >
            <iframe class="embed-responsive-item"  [src]="video.safeLink" allow="accelerometer; fullscreen; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center">
    <ngb-pagination *ngIf="pagination != null" [collectionSize]="pagination.TotalCount" [(page)]="pagination.CurrentPage" [pageSize]="pagination.PageSize" (pageChange)="getVideos()" aria-label="Default pagination"></ngb-pagination>
</div>