<div class="row">
    <div class="col-12">
        <ngb-carousel [keyboard]="true" [pauseOnHover]="false" [interval]="10000">
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="../../assets/images/carousel1.jpg">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="../../assets/images/carousel2.jpg">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="../../assets/images/carousel3.jpg">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img src="../../assets/images/carousel4.jpg">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
    
    <!---->
</div>
<div class="row" id="home-presentation">
    <div class="col">
        {{'home.presentation'|translate}}
    </div>
</div>



    