import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ArtistModel } from '../models/artist.model';
import { PaginationModel } from '../models/pagination.model';
import { ArtistsService } from '../services/artistsService';
import { PicturesService } from '../services/picturesService';

import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ns-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.css']
})
export class ArtistsComponent implements OnInit, OnDestroy {

  artists: Array<ArtistModel> | null;

  pagination: PaginationModel;

  nameForm: FormGroup;

  nameCtrl: FormControl;

  loaded:boolean = false;
  apiNotResponding: boolean = false;


  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private artistsService: ArtistsService
    , private picturesService: PicturesService
    , private fb: FormBuilder) 
  {
    this.nameCtrl = fb.control('');

    this.nameForm = fb.group({
      name: this.nameCtrl
    });

    this.nameCtrl.valueChanges
    .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.unsubscribe$))
    .subscribe(() => this.getArtists());
  }

  
  ngOnInit(): void {
   this.getArtists();   
  }

  getArtists()
  {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.apiNotResponding = false;
    this.loaded = false;
    
    this.artistsService.getArtists(this.pagination?.CurrentPage ? this.pagination.CurrentPage : 1, this.nameForm.get('name')?.value)
    .pipe(
      finalize(() => {
        this.loaded = true;
      })
    )
    .subscribe(
      resp => {
        let paginationString = resp.headers.get('X-Pagination');
        this.pagination = JSON.parse(paginationString ? paginationString : '');

        this.artists = resp.body;
        //`url('${this.pictureService.getArtistPicture(a.picture)} large')`
        //this.artists?.forEach(a => a.picture = `url('${this.picturesService.getArtistPicture(a.picture)}')`);
        this.artists?.forEach(a => a.picture = this.picturesService.getArtistPicture(a.picture) );
      },
      err => {
        this.apiNotResponding = true;
      });
  }

  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
