import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { ArtistModel } from '../models/artist.model';
import { PaginationModel } from '../models/pagination.model';
import { ReleaseModel } from '../models/release.model';
import { ArtistsService } from '../services/artistsService';
import { PicturesService } from '../services/picturesService';
import { ReleasesService } from '../services/releasesService';

@Component({
  selector: 'ns-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.css']
})
export class ReleasesComponent implements OnInit {

  releases: Array<ReleaseModel> | null;

  pagination: PaginationModel;

  nameForm: FormGroup;

  nameCtrl: FormControl;

  artists: Array<ArtistModel>;

  artistName: string ="";
  
  loaded:boolean = false;
  apiNotResponding: boolean = false;


  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private releasesService: ReleasesService, private picturesService: PicturesService
    , private fb: FormBuilder, private artistsService: ArtistsService) 
    { 
      this.nameCtrl = fb.control('');

      this.nameForm = fb.group({
        name: this.nameCtrl
      });

      this.nameCtrl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(() => 
      {
        this.getReleases();
      });
    }

  ngOnInit(): void {
    this.getReleases();

    this.artistsService.getArtistsNames().subscribe(i => {
      this.artists = i;
    });
    
  }

  getReleases(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.apiNotResponding = false;
    this.loaded = false;
    this.releasesService.getReleases(this.pagination?.CurrentPage ? this.pagination.CurrentPage : 1
      , this.nameForm.get('name')?.value
      , this.artistName)
      .pipe(
        finalize(() => {
          this.loaded = true;
        })
      )
      .subscribe(
        resp => {
          let paginationString = resp.headers.get('X-Pagination');
          this.pagination = JSON.parse(paginationString ? paginationString : '');

          this.releases = resp.body;
          this.releases?.forEach(i => i.picture = this.picturesService.getReleasePicture(i.picture) );
        },
        err => {
          this.apiNotResponding = true;
        });
  }

  filterByArtist(artistName: string){
    if(this.artistName === artistName)
    {
      this.artistName = '';
    }
    else
    {
      this.artistName = artistName;
    }
    this.getReleases();
  }

  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
