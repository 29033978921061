import { Component, Input, OnInit } from '@angular/core';
import { ReleaseModel } from '../models/release.model';
import { ArtistsService } from '../services/artistsService';
import { PicturesService } from '../services/picturesService';

@Component({
  selector: 'ns-artist-releases',
  templateUrl: './artist-releases.component.html',
  styleUrls: ['./artist-releases.component.css']
})
export class ArtistReleasesComponent implements OnInit {

@Input() artistName: string;

releases: Array<ReleaseModel>;

  constructor(private artistsService: ArtistsService, private picturesService: PicturesService) 
  {

   }

  ngOnInit(): void {
    this.artistsService.getReleasesByArtist(this.artistName).subscribe(r =>
      {
        this.releases = r;

        this.releases.forEach(i => i.picture = this.picturesService.getReleasePicture(i.picture));
      });
  }

}
