import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlsService } from './services/urlsService';

@Component({
  selector: 'ns-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'avanticlassic';

  isMenuCollapsed = true;



  constructor(public translate: TranslateService, public urlsService: UrlsService)
  {
    translate.addLangs(['en', 'fr', 'de']);
        let lang: string | undefined = localStorage.getItem("language")?.toString();
        translate.use(lang ? lang : 'en');
        
    
        
  }

  switchLang(lang: string){
    this.translate.use(lang);
    localStorage.setItem("language", lang);
  }
}
