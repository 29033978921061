
<div class="row justify-content-end">
    <div class="col-12 col-md-4">
        <form [formGroup]="nameForm" class="form-inline">
            <div class="input-group">
                <div class="input-group-addon">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" class="bi bi-search" viewBox="0 0 19 19">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                    </span>
                </div>
                <input formControlName="name" class="form-control">
            </div>  
        </form>
    </div> 
</div>


<div class="row justify-content-center">
    <div *ngIf="loaded == false" class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="apiNotResponding" class="m-5">
        <span>{{'apiNotResponding' | translate }}</span>
    </div>
    <div class="col-6 col-md-4 img__wrap" *ngFor="let artist of artists">
        <a [routerLink]="['/artists', artist.fullName]" >
            <img class="img__img" src="{{artist.picture}}"/>
                <div class="img__description">
                    <p>{{artist.fullName}}</p>
                </div>
        </a>    
    </div>
</div>
       
<div class="d-flex justify-content-center">
    <ngb-pagination *ngIf="pagination != null" [collectionSize]="pagination.TotalCount" [(page)]="pagination.CurrentPage" [pageSize]="pagination.PageSize" (pageChange)="getArtists()" aria-label="Default pagination"></ngb-pagination>
</div>


