<div class="row justify-content-center">
    <div *ngIf="loaded == false" class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="apiNotResponding" class="m-5">
        <span>{{'apiNotResponding' | translate }}</span>
    </div>
</div>
<div *ngIf="loaded == true && apiNotResponding == false">

<div class="d-none d-lg-block artist-big-screen">
    <img src="{{release?.picture}}">
    <p id="artistsTitle">{{'artists' | translate}}:</p> 
    <a *ngFor="let artist of artists" class="artistName" [routerLink]="['/artists', artist.fullName]">
        {{artist.fullName}}
    </a>
</div>
<div class="d-xs-block d-lg-none artist-small-screen">
    <img src="{{release?.picture}}">
    <p id="artistsTitle">{{'artists' | translate}}:</p> 
    <a *ngFor="let artist of artists" class="artistName" [routerLink]="['/artists', artist.fullName]">
        {{artist.fullName}}
    </a>
</div>

<h1>{{release?.name}}</h1>
<a *ngIf="release?.paypal != null" href="{{release?.paypal}}" class="btn" role="button" target="_blank">{{'buy' | translate}}</a>
<p id="description" [innerHtml]="release?.description"></p>

<ngb-accordion>
    <ngb-panel [disabled]="release?.press == null">
        <ng-template ngbPanelTitle>
            <span class="panelTitle">Press</span>
        </ng-template>
        <ng-template ngbPanelContent>
            <div id="press" [innerHtml]="release?.press"></div>
        </ng-template>
    </ngb-panel>
    <ngb-panel [disabled]="release?.tracklist == null">
        <ng-template ngbPanelTitle>
            <span class="panelTitle">Tracklist</span>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="trackList">
                <div [innerHTML]="release?.tracklist"></div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>

</div>