import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlsService } from './urlsService';
import { DistributorModel } from '../models/distributor.model';

@Injectable()
export class DistributorsService {

    constructor(private http: HttpClient, private urlsService: UrlsService)
    {

    }

    getDistributors(pageNumber: number, distributorName: string): Observable<HttpResponse<Array<DistributorModel>>>
    {
        const url: string = this.urlsService.baseUrl + 'distributors/';
        return this.http.get<Array<DistributorModel>>(url,{ params: {pageNumber: pageNumber.toString(), name: distributorName}, observe: 'response'});
    }
  
}