import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpResponse } from '@angular/common/http';
import { ArtistModel } from '../models/artist.model';
import { Observable } from 'rxjs';
import { UrlsService } from './urlsService';
import { ReleaseModel } from '../models/release.model';
import { VideoModel } from '../models/video.model';

@Injectable()
export class VideosService {

    constructor(private http: HttpClient, private urlsService: UrlsService)
    {

    }

    getVideos(pageNumber: number, videoName: string, releaseName: string, artistName: string): Observable<HttpResponse<Array<VideoModel>>>
    {
        const url: string = this.urlsService.baseUrl + 'videos/';
        return this.http.get<Array<VideoModel>>(url,{ params: {pageNumber: pageNumber.toString(), name: videoName, release: releaseName, artist: artistName}, observe: 'response'});
    }
  
}