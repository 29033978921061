<div class="row">
    <div class="col-12">
        <p id="about">{{'home.presentation'|translate}}</p>
    </div>
    <div class="col-12">
        <p id="distributorTitle">{{'listOfDistributors' | translate | uppercase}}:</p>
    </div>
</div>
<div class="row justify-content-center">
    <div *ngIf="loaded == false" class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="apiNotResponding" class="m-5">
        <span>{{'apiNotResponding' | translate }}</span>
    </div>

    <div class="col-12 col-md-4" *ngFor="let distributor of distributors">
        <p class="country">{{distributor.country.name}}</p>
        <p>{{distributor.name}}</p>
        <p>{{distributor.address}}</p>
        <p>{{'website'|translate}}: {{distributor.website}}</p>
        <p>{{'email'|translate}}: {{distributor.email}}</p>
        <p>{{'phone'|translate}}: {{distributor.phone}}</p>
    </div>
</div>

<div class="d-flex justify-content-center">
    <ngb-pagination *ngIf="pagination != null" [collectionSize]="pagination.TotalCount"
        [(page)]="pagination.CurrentPage" [pageSize]="pagination.PageSize" (pageChange)="getDistributors()"
        aria-label="Default pagination"></ngb-pagination>
</div>