<div class="row">
    <div class="col-6 col-xl-4 release" *ngFor="let release of releases">
        <a [routerLink]="['/releases', release.id]">
            <img src="{{release.picture}}">
            <div>
                <p>{{release.name}}</p>
            </div>
        </a>
    </div>
</div>
    
