import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { DistributorModel } from '../models/distributor.model';
import { PaginationModel } from '../models/pagination.model';
import { DistributorsService } from '../services/distributorsService';

@Component({
  selector: 'ns-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  distributors: Array<DistributorModel> | null;

  pagination: PaginationModel;

  loaded: boolean = false;
  apiNotResponding: boolean = false;

  constructor(private distributorsService: DistributorsService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.getDistributors();

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getDistributors();
    });


  }

  getDistributors(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.apiNotResponding = false;
    this.loaded = false;

    this.distributorsService.getDistributors(this.pagination?.CurrentPage ? this.pagination.CurrentPage : 1, '')
      .pipe(
        finalize(() => {
          this.loaded = true;
        })
      ).subscribe(resp => {
        let paginationString = resp.headers.get('X-Pagination');
        this.pagination = JSON.parse(paginationString ? paginationString : '');

        this.distributors = resp.body;

        switch (this.translate.currentLang) {
          case 'fr':
            this.distributors?.map(d => d.country.name = d.country.names.find(n => n.languageId == 1)!.text);
            break
          case 'de':
            this.distributors?.map(d => d.country.name = d.country.names.find(n => n.languageId == 3)!.text);
            break
          default:
            this.distributors?.map(d => d.country.name = d.country.names.find(n => n.languageId == 2)!.text);
            break;
        }
      },
        err => {
          this.apiNotResponding = true;
        });

  }

}
