import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpResponse } from '@angular/common/http';
import { ReleaseModel } from '../models/release.model';
import { Observable } from 'rxjs';
import { UrlsService } from './urlsService';
import { ArtistModel } from '../models/artist.model';
import { ReleaseForManipulationModel } from '../models/release-for-manipulation.model';

@Injectable()
export class ReleasesService {

    constructor(private http: HttpClient, private urlsService: UrlsService)
    {

    }

    getReleases(pageNumber: number, releaseName: string, artistName: string): Observable<HttpResponse<Array<ReleaseModel>>>
    {
        const url: string = this.urlsService.baseUrl + 'releases/';
        return this.http.get<Array<ReleaseModel>>(url,{ params: {pageNumber: pageNumber.toString(), pageSize: "12", name: releaseName, artist: artistName}, observe: 'response'});
    }

    
    getAllReleases(): Observable<Array<ReleaseModel>>
    {
        const url: string = this.urlsService.baseUrl + 'releases/';
        
        return this.http.get<Array<ReleaseModel>>(url, {params: {pageSize:'99'}});
    }
    
    getReleasesNames(): Observable<Array<ReleaseModel>>
    {
        const url: string = this.urlsService.baseUrl + 'releases/';
        return this.http.get<Array<ReleaseModel>>(url, { params: {fields: 'name', pageSize: '99'}});
    }
    
    getRelease(releaseId: string): Observable<ReleaseModel>
    {
        const url: string = this.urlsService.baseUrl + `releases/${releaseId}`;
        return this.http.get<ReleaseModel>(url);
    }

  
    getArtistsByRelease(releaseId: string): Observable<Array<ArtistModel>>
    {
        const url: string = this.urlsService.baseUrl + `releases/${releaseId}/artists`;
        return this.http.get<Array<ArtistModel>>(url);
    }

   
}