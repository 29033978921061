import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ArtistModel } from '../models/artist.model';
import { ReplacePipe } from '../pipes/replace.pipe';
import { ArtistsService } from '../services/artistsService';
import { PicturesService } from '../services/picturesService';

@Component({
  selector: 'ns-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.css']
})
export class ArtistComponent implements OnInit {

  artist: ArtistModel;
  pictureLink: string;
  
  loaded:boolean = false;
  apiNotResponding: boolean = false;

  constructor(private route: ActivatedRoute, 
    private artistsService: ArtistsService, 
    private pictureService: PicturesService,
    private translate: TranslateService,
    private meta:Meta,
    private replacePipe: ReplacePipe,
    ) {
      //let name = route.snapshot.paramMap.get('artistName')!;
      //this.meta.updateTag({ property: 'og:image', content: this.pictureService.getArtistPicture(name)});
      //this.meta.updateTag({ property: 'og:title', content: name});
   }

  ngOnInit(): void {
    this.getArtist();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getArtist();
  });
    
  }

  getArtist(): void{
    this.apiNotResponding = false;
    this.loaded = false;

    const name = this.route.snapshot.paramMap.get('artistName');

    if(name != null)
    {
        this.artistsService.getArtist(name)
        .pipe(
          finalize(() => {
            this.loaded = true;
          })
        )
        .subscribe(a =>
          {
            this.artist = {
              id : a.id,
              description : a.description,
              descriptions : a.descriptions,
              fullName : a.fullName,
              firstName: a.firstName,
              lastName: a.lastName,
              facebook : a.facebook,
              //picture : `url('${this.pictureService.getArtistPicture(a.picture)}')`
              picture : `${this.pictureService.getArtistPicture(a.picture + ' LARGE')}`
            }

            switch (this.translate.currentLang) {
              case 'fr':
                this.artist.description = a.descriptions.find(i => i.languageId == 1)!.text;
                break
                case 'de':
                  this.artist.description = a.descriptions.find(i => i.languageId == 3)!.text;
                  break
                  default:
                    this.artist.description = a.descriptions.find(i => i.languageId == 2)!.text;
                    break;
            }
            this.pictureLink = `url('${this.artist.picture}')`;
          },
          err => {
                this.apiNotResponding = true;
            });
    }
  }

}
