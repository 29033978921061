import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UrlsService } from "./urlsService";


@Injectable()
export class PicturesService {

    constructor(private http: HttpClient, private urlsService: UrlsService)
    {

    }

    getArtistsPictureName(): Observable<Array<string>>
    {
        const url: string = this.urlsService.baseUrl + `pictures/artists`;
        return this.http.get<Array<string>>(url);
    }

    getArtistPicture(pictureName: string): string
    {
        const url: string = this.urlsService.baseUrl + `pictures/artists/${pictureName}`
        return url;
    }

    
    getReleasePicture(pictureName: string): string
    {
        const url: string = this.urlsService.baseUrl + `pictures/releases/${pictureName}`
        return url;
    }
    getReleasesPictureName(): Observable<Array<string>>
    {
        const url: string = this.urlsService.baseUrl + `pictures/releases`
        return this.http.get<Array<string>>(url);
    }
  
}