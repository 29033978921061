<div class="row justify-content-center">
  <div *ngIf="loaded == false" class="spinner-border m-5" role="status">
      <span class="sr-only">Loading...</span>
  </div>
  <div *ngIf="apiNotResponding" class="m-5">
      <span>{{'apiNotResponding' | translate }}</span>
  </div>
</div>

<div *ngIf="loaded == true && apiNotResponding == false">
  <div class="row justify-content-center">
    <div class="col-12 d-none d-lg-table jumbotron paral" [ngStyle]="{'background-image': pictureLink}">
      <h1>{{artist?.fullName}}</h1>
    </div>
    <div class="d-xs-block  d-lg-none img_container">
      <img [src]="artist?.picture" style="width: 100%;">
    </div>
  
  
  
  <div class="row justify-content-center">
      <div class="col-12 col-lg-4">
          <div id="name">{{artist?.fullName}}</div>
          <a *ngIf="artist?.facebook" href="{{artist?.facebook}}" >
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
              <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>
          </a>
      </div>
      <div class="col-12 col-lg-8">
  
          <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
              <li ngbNavItem>
                <a ngbNavLink>{{'biography' | translate}}</a>
                <ng-template ngbNavContent>
                  <p id="description" [innerHtml]="artist?.description"></p>
                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink>{{'releases' | translate}}</a>
                <ng-template ngbNavContent>
                    <ns-artist-releases *ngIf="artist != null" [artistName]="artist.fullName"></ns-artist-releases>
                  </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
  
          
      </div>
  </div>
  
</div>


