import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class UrlsService {

    baseUrl: string = environment.apiUrl;

    constructor()
    {

    }

  

}