import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { ArtistModel } from '../models/artist.model';
import { PaginationModel } from '../models/pagination.model';
import { ReleaseModel } from '../models/release.model';
import { VideoModel } from '../models/video.model';
import { ArtistsService } from '../services/artistsService';
import { ReleasesService } from '../services/releasesService';
import { VideosService } from '../services/videosService';

@Component({
  selector: 'ns-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit, OnDestroy {

  videos: Array<VideoModel> | null;

  pagination: PaginationModel;

  nameForm: FormGroup;

  nameCtrl: FormControl;

  artists: Array<ArtistModel>;
  releases: Array<ReleaseModel>;

  artistName: string ="";
  releaseName: string = "";
  
  loaded:boolean = false;
  apiNotResponding: boolean = false;
  
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private videosService: VideosService, private dom:DomSanitizer
    , private fb: FormBuilder, private releasesService: ReleasesService, private artistsService:ArtistsService) 
    { 
      this.nameCtrl = fb.control('');

      this.nameForm = fb.group({
        name: this.nameCtrl
      });

      this.nameCtrl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(() => this.getVideos());
    }

  ngOnInit(): void {
    this.getVideos();

    this.artistsService.getArtistsNames().subscribe(i => this.artists = i);
    this.releasesService.getReleasesNames().subscribe(i => this.releases = i);
  }
  
  getVideos()
  {  
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.apiNotResponding = false;
    this.loaded = false;
    
    this.videosService.getVideos(this.pagination?.CurrentPage ? this.pagination.CurrentPage : 1, this.nameForm.get('name')?.value, this.releaseName, this.artistName)
    .pipe(
      finalize(() => {
        this.loaded = true;
      })
    )
    .subscribe(resp => 
      {
        let paginationString = resp.headers.get('X-Pagination');
        this.pagination = JSON.parse(paginationString ? paginationString : '');

              this.videos = resp.body;
              this.videos?.forEach(v => v.safeLink = this.dom.bypassSecurityTrustResourceUrl(v.link));    

          },
          err => {
            this.apiNotResponding = true;
          });
  
  }

  filterByArtistName(artistName: string){
    if(this.artistName === artistName)
    {
      this.artistName = '';
    }
    else
    {
      this.artistName = artistName;
    }
    this.getVideos();
  }

  filterByReleaseName(releaseName: string){
    if(this.releaseName === releaseName)
    {
      this.releaseName = '';
    }
    else
    {
      this.releaseName = releaseName;
    }
    this.getVideos();
  }

  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
