import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ROUTES } from './app.routes';
import { ArtistsComponent } from './artists/artists.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ArtistsService } from './services/artistsService';
import { UrlsService } from './services/urlsService';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ArtistComponent } from './artist/artist.component';
import { PicturesService } from './services/picturesService';
import { ArtistReleasesComponent } from './artist-releases/artist-releases.component';
import { ReleasesComponent } from './releases/releases.component';
import { ReleasesService } from './services/releasesService';
import { ReleaseComponent } from './release/release.component';
import { VideosComponent } from './videos/videos.component';
import { VideosService } from './services/videosService';
import { DistributorsService } from './services/distributorsService';
import { AboutComponent } from './about/about.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ReplacePipe } from './pipes/replace.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ArtistsComponent,
    ArtistComponent,
    ArtistReleasesComponent,
    ReleasesComponent,
    ReleaseComponent,
    VideosComponent,
    AboutComponent,
    ReplacePipe
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    NgbModule,
    RouterModule,
    RouterModule.forRoot(ROUTES, {scrollPositionRestoration: 'enabled'}),
    HttpClientModule,
    ReactiveFormsModule,
  ],
  providers: [ArtistsService, UrlsService, PicturesService, ReleasesService, VideosService, DistributorsService,
    ReplacePipe
    ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}


export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
