import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ArtistModel } from '../models/artist.model';
import { Observable } from 'rxjs';
import { UrlsService } from './urlsService';
import { ReleaseModel } from '../models/release.model';
import { ArtistForManipulationModel } from '../models/artist-for-manipulation.model';

@Injectable()
export class ArtistsService {

    constructor(private http: HttpClient, private urlsService: UrlsService)
    {

    }

    getArtists(pageNumber: number, artistName: string): Observable<HttpResponse<Array<ArtistModel>>>
    {
        const url: string = this.urlsService.baseUrl + 'artists/';
        
         return this.http.get<Array<ArtistModel>>(url,{ params: {pageNumber: pageNumber.toString(), name: artistName}, observe: 'response'});
    }

    getAllArtists(): Observable<Array<ArtistModel>>
    {
        const url: string = this.urlsService.baseUrl + 'artists/';
        
        return this.http.get<Array<ArtistModel>>(url, {params: {pageSize:'99'}});
    }

    getArtistsNames(): Observable<Array<ArtistModel>>
    {
        const url: string = this.urlsService.baseUrl + 'artists/';
        return this.http.get<Array<ArtistModel>>(url, { params: {fields: 'FullName', pageSize: '99'}});
    }
    
    getArtist(name: string): Observable<ArtistModel>
    {
        const url: string = this.urlsService.baseUrl + `artists/${name}`;
        return this.http.get<ArtistModel>(url);
    }

    getReleasesByArtist(artistName: string): Observable<Array<ReleaseModel>>
    {
        const url: string = this.urlsService.baseUrl + `artists/${artistName}/releases`;
        return this.http.get<Array<ReleaseModel>>(url);
    }

    
    
}