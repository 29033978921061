import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { ArtistModel } from '../models/artist.model';
import { ReleaseModel } from '../models/release.model';
import { PicturesService } from '../services/picturesService';
import { ReleasesService } from '../services/releasesService';
import { VideosService } from '../services/videosService';

@Component({
  selector: 'ns-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.css']
})
export class ReleaseComponent  implements OnInit {

  release: ReleaseModel;
  pictureLink: string;
  artists: Array<ArtistModel>;

  id: string|null;
  
  loaded:boolean = false;
  apiNotResponding: boolean = false;

  constructor(private route: ActivatedRoute, 
    private releasesService: ReleasesService, 
    private pictureService: PicturesService,
    private translate: TranslateService,
    private dom:DomSanitizer) {
      this.id = this.route.snapshot.paramMap.get('releaseId');
   }

  ngOnInit(): void {
    this.getRelease();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getRelease();
    }); 

    this.getArtists();
  }

  getRelease(): void{

    this.apiNotResponding = false;
    this.loaded = false;

    if(this.id != null)
    {
        this.releasesService.getRelease(this.id)
        .pipe(
          finalize(() => {
            this.loaded = true;
          })
        ).subscribe(a =>
        {
          this.release = {
            id : a.id,
            description : a.description,
            descriptions: a.descriptions,
            name : a.name,
            paypal: a.paypal,
            picture : this.pictureService.getReleasePicture(a.picture),
            videos: a.videos,
            tracklist: a.tracklist,
            press: a.press,
            presses: a.presses,
            date: a.date
          };

          switch (this.translate.currentLang) {
            case 'fr':
              this.release.description = a.descriptions.find(i => i.languageId == 1)!.text;
              break
              case 'de':
                this.release.description = a.descriptions.find(i => i.languageId == 3)!.text;
                break
                default:
                  this.release.description = a.descriptions.find(i => i.languageId == 2)!.text;
                  break;
          }
          switch (this.translate.currentLang) {
            case 'fr':
              this.release.press = a.presses.find(i => i.languageId == 1)!.text;
              break
              case 'de':
                this.release.press = a.presses.find(i => i.languageId == 3)!.text;
                break
                default:
                  this.release.press = a.presses.find(i => i.languageId == 2)!.text;
                  break;
          }

          this.release?.videos?.forEach(v => v.safeLink = this.dom.bypassSecurityTrustResourceUrl(v.link));
        },
        err => {
          this.apiNotResponding = true;
        });  
    }
  }

  getArtists(): void{
    if(this.id != null)
    {
      this.releasesService.getArtistsByRelease(this.id).subscribe(a =>
        {
          this.artists = a;
        });
    }
  }

}
