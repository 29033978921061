import { Routes } from "@angular/router";
import { AboutComponent } from "./about/about.component";
import { ArtistComponent } from "./artist/artist.component";
import { ArtistsComponent } from "./artists/artists.component";
import { HomeComponent } from "./home/home.component";
import { ReleaseComponent } from "./release/release.component";
import { ReleasesComponent } from "./releases/releases.component";
import { ArtistResolver } from "./resolvers/artist.resolver";
import { VideosComponent } from "./videos/videos.component";

export const ROUTES: Routes = 
[
    { path: '', component: HomeComponent },
    { path: 'artists', component: ArtistsComponent },
    { path: 'artists/:artistName', component: ArtistComponent},
    { path: 'releases', component: ReleasesComponent },
    { path: 'releases/:releaseId', component: ReleaseComponent },
    { path: 'videos', component: VideosComponent },
    { path: 'about', component: AboutComponent },
    {path: '**', redirectTo: ''}
]